.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-title {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Page-header {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Page-section {
  display: flex;
  flex-direction: column;
  font-size: calc(2px + 2vmin);
  color: white;
  margin-left: 20px;
}


.Page {
  background-color: #282c34;
  min-height: 100vh;
}

.Page-title {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Product-privacy-link {
  font-size: calc(3px + 2vmin);
  color: white;
}

.Product-support-link {
  font-size: calc(3px + 2vmin);
  color: white;
}

.Product-short-description {
  display: flex;
  flex-direction: column;
  font-size: calc(3px + 2vmin);
  color: white;
  margin-left: 20px;
}

.Product-long-description {
  display: flex;
  flex-direction: column;
  font-size: calc(2px + 2vmin);
  color: white;
  margin-left: 20px;
}

.card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 300px;
  margin: 1rem;
  position: relative;
  width: 300px;
}

.card-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 300px;  /* change this to whatever you want */
  width: auto;
}
